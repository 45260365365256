<template>
  <div class="bank-book" :class="{skeleton: !state.loaded }">
    <template v-if="state.account.virtualAccountName">
      <div class="section pb-0">
        <div class="subject color-white">
          <span>OMC 계좌</span>
        </div>
      </div>
      <div class="section">
        <div class="subject">
          <span>계좌주</span>
        </div>
        <div class="value font-md">
          <span>
            <b>{{ state.account.virtualAccountName }}</b>
          </span>
          <button class="btn btn-outline-light font-sm" @click="$store.commit('openModal', {name: 'BankBookHistory'})">입출금 내역</button>
        </div>
      </div>
      <div class="section">
        <div class="subject">
          <span>계좌번호</span>
        </div>
        <div class="value font-md">
          <span>
            <b>신한은행 </b>
            <span>{{ state.account.virtualAccountNum }}</span>
          </span>
          <button class="btn btn-outline-light font-sm" @click="copyVirtualAccount(state.account.virtualAccountNum)">계좌 복사</button>
        </div>
      </div>
      <div class="section">
        <div class="subject">
          <span>계좌 잔고</span>
        </div>
        <div class="value font-md">
          <span>
            <b>{{ $lib.getNumberFormat(state.account.depositTotalAmount) }}</b><span>원</span>
          </span>
          <button class="btn btn-outline-light font-sm" @click="load()">새로고침</button>
        </div>
      </div>
    </template>
    <div class="none" v-else>
      <div>OMC 계좌가 없습니다.</div>
      <div class="pt-2">출금 계좌의 계좌번호를 등록해주세요.</div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import http from "@/scripts/http";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "componentBankBook";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    func: Function
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      account: {
        depositTotalAmount: 1000,
        monthlyWithdrawCount: 0,
        virtualAccountName: "Wait a moment",
        virtualAccountNum: "Wait a moment",
        withdrawalAmount: 0,
      }
    });

    const copyVirtualAccount = (virtualAccountNum) => {
      lib.copyToClipboard("신한은행 " + virtualAccountNum.trim());
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const load = () => {
      state.loaded = false;
      http.get(`/api/invest/${store.state.investor.investorSeq}/deposit-account`).then(({data}) => {
        state.loaded = true;
        state.account = data.body;
        typeof props.func === "function" && props.func(true);
      }).catch(httpError(() => {
        typeof props.func === "function" && props.func(false);
      }));
    };

    return {component, state, copyVirtualAccount, load};
  },
});
</script>

<style lang="scss" scoped>
.bank-book {
  background: #49545f;
  color: #fff;
  padding: $px10 $px25;
  border-radius: $px4;

  .section {
    padding: $px15 0;

    .subject {
      color: rgba(255, 255, 255, 0.76);
      margin-bottom: $px4;
    }

    .value {
      b, span {
        vertical-align: middle;
      }

      .btn {
        margin-top: $px-15;
        float: right;
        width: $px90;
        padding: $px10 0;
      }
    }
  }

  > .none {
    padding: $px15 0;
  }

  &.skeleton {
    .section {
      .subject > span {
        @include skeleton
      }

      .value {
        > b, > span, > button {
          @include skeleton
        }
      }
    }
  }

  @media(max-width: 767px) {
    .section .value .btn {
      float: none;
      margin-top: $px15;
      width: 100%;
    }
  }
}
</style>